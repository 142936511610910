import { incrementCounter } from "#utils/firebase"
import { useLocation } from "@gatsbyjs/reach-router"
import React from "react"

const ExternalLink = ({ children, ...props }) => {
  const location = useLocation()
  function incrementExternalLinkClick(link) {
    incrementCounter(
      location,
      `externalLinks/${link.replaceAll(" ", "-").toLowerCase()}`
    )
  }

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      {...props}
      onClick={() => incrementExternalLinkClick(children)}
    >
      {children}
    </a>
  )
}

export default ExternalLink
