import Highlight, { defaultProps } from "prism-react-renderer"
import theme from "prism-react-renderer/themes/okaidia"
import React from "react"
import { LiveEditor, LiveError, LivePreview, LiveProvider } from "react-live"
// import theme from "prism-react-renderer/themes/oceanicNext"
// import theme from "prism-react-renderer/themes/vsDark"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { cx } from "../../../utils/all"
const copyToClipboard = str => {
  const el = document.createElement("textarea")
  el.value = str
  el.setAttribute("readonly", "")
  el.style.position = "absolute"
  el.style.left = "-9999px"
  document.body.appendChild(el)
  el.select()
  document.execCommand("copy")
  document.body.removeChild(el)
}

const Button = props => (
  <button
    className="hover:bg-gray-700"
    style={{
      position: "absolute",
      top: 0,
      right: 0,
      border: "none",
      boxShadow: "none",
      textDecoration: "none",
      marginTop: "16px",
      padding: "8px 12px",
      background: "#E2E8F022",
      borderRadius: "15px",
      cursor: "pointer",
      color: "#E2E8F0",
      fontSize: "14px",
      fontFamily: "sans-serif",
      lineHeight: "1",
    }}
    {...props}
  />
)

export const Code = ({ codeString, language, metastring, title, collapsed, ...props }) => {
  const [isCopied, setIsCopied] = React.useState(false);

  const opened = (collapsed !== "true" ? { open: true } : null);

  if (props["react-live"]) {
    return (
      <LiveProvider code={codeString} noInline={true}>
        <LiveEditor />
        <LiveError />
        <LivePreview />
      </LiveProvider>
    )
  } else {
    return (
      <details {...opened} className=" bg-gray-800 pl-6 pr-6 rounded-lg pb-0 text-gray-300">
        <summary className="relative h-16 leading-[4rem] cursor-pointer">
          <b>
            <ChevronDownIcon className="w-5 h-5 inline"></ChevronDownIcon>&nbsp;
            {title ? title.replaceAll('"', '').replaceAll('_', ' ') : 'Code preview'}
          </b>
          <div className="bg-gray-900 w-max h-8 leading-[2rem] px-4 rounded-2xl text-xs inline-block absolute right-0 top-[1.1rem]" >
            {language}
          </div>
        </summary>
        <Highlight
          {...defaultProps}
          code={codeString}
          language={language}
          theme={theme}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre
              className={cx(className, "whitespace-pre-wrap text-[0.7rem] md:text-xs break-all w-full rounded-none mt-1 p-0 !bg-gray-800 border-t border-t-gray-700")}
              style={{
                ...style,
              }}
            >
              <div
                className="flex relative pb-8"
              >
                <Button
                  onClick={() => {
                    copyToClipboard(codeString)
                    setIsCopied(true)
                    setTimeout(() => setIsCopied(false), 3000)
                  }}
                >
                  {isCopied ? "✔ Copied!" : "📋 Copy"}
                </Button>
              </div>
              {tokens.map((line, i) => (
                <div
                  {...getLineProps({ line, key: i })}
                  className="px-0"
                >
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}

              <div
                className="flex relative pb-8"
              >
              </div>
            </pre>
          )}
        </Highlight>
      </details>
    )
  }
}
