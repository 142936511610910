import { LinkIcon } from '@heroicons/react/24/outline'
import React from 'react'

const GitHubEmbed = ({ children, title, description, imageUrl, ...props }) => (
  <a target="_blank" rel="noopener noreferrer" className='no-underline cursor-pointer' {...props}>
    <div className="w-full lg:max-w-full lg:flex group">
      <div className="h-48 lg:h-auto lg:w-48 flex-none bg-cover object-center rounded-t-lg lg:rounded-t-none lg:rounded-l-lg text-center overflow-hidden border-l border-t lg:border-b border-r lg:border-r-0 border-[#e5e5ef] dark:border-none" style={{ backgroundImage: `url('${imageUrl}')` }} title="Woman holding a mug">
      </div>
      <div className="bg-[#e5e5ef] dark:bg-neutral-800 rounded-b-lg lg:rounded-b-none lg:rounded-r-lg p-4 flex flex-col justify-between leading-normal">
        <div>
          <p className="text-sm text-gray-600 dark:text-stone-300 flex items-center transition-all group-hover:text-blue-500">
            <LinkIcon className='w-4 h-4'></LinkIcon>&nbsp;
            Repository
          </p>
          <h4 className="text-gray-900 dark:text-stone-300 leading-[1.3rem] w-max font-bold text-lg bg-gradient-to-r from-gray-300 dark:from-blue-900 bg-[length:0px_10px] bg-left-bottom bg-no-repeat transition-[background-size] duration-500 hover:bg-[length:100%_10px] group-hover:bg-[length:100%_10px]">
            {title}
          </h4>
          <p className="text-gray-700 text-sm dark:text-stone-300">
            {description}
          </p>
        </div>
      </div>
    </div>
    {children}
  </a>
)

export default GitHubEmbed