import ExternalLink from "../../../../src/components/blog/post/external-link";
import ComponentLink from "../../../../src/components/blog/post/component-link";
import GitHubEmbed from "../../../../src/components/blog/post/github-embed";
import { BookOpenIcon, FolderIcon, PencilIcon, PhotoIcon, PuzzlePieceIcon, StarIcon, VideoCameraIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import * as React from 'react';
export default {
  ExternalLink,
  ComponentLink,
  GitHubEmbed,
  BookOpenIcon,
  FolderIcon,
  PencilIcon,
  PhotoIcon,
  PuzzlePieceIcon,
  StarIcon,
  VideoCameraIcon,
  CheckCircleIcon,
  React
};