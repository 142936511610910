import ComponentDatabase from "#constants/ComponentDatabase";
import React from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from './../../../../tailwind.config.js';
var stringSimilarity = require("string-similarity");


const ComponentLink = ({ children, componentId, ...props }) => {

  const fullConfig = resolveConfig(tailwindConfig)
  const allowedWidth = parseInt(fullConfig.theme.screens.md.replace('px', ''))

  if (!ComponentDatabase[componentId]) {
    var matches = stringSimilarity.findBestMatch(componentId, Object.keys(ComponentDatabase));
    const similarComponentId = matches.bestMatch.target;
    const componentSimilarity = stringSimilarity.compareTwoStrings(componentId, similarComponentId);

    if (componentSimilarity > 0.5) {
      throw new Error(`Component with the id ${componentId} doesn't exist! Did you mean ${similarComponentId}?`);
    }

    throw new Error(`Component with the id ${componentId} doesn't exist! You should add it to the ComponentDatabase.`);
  }

  function onHover(event) {
    if (window.innerWidth < allowedWidth) {
      return;
    }

    const componentId = event.target.dataset.component;

    const componentList = document.querySelector('.component-list-wrapper');

    const allComponents = document.querySelectorAll('.component-list-wrapper a');

    for (const component of allComponents) {
      component.classList.add('opacity-20');
    }

    const selectedComponent = componentList.querySelector(`a[data-component="${componentId}"]`);

    selectedComponent.classList.remove('opacity-20');
  }

  function onHoverEnd() {
    if (window.innerWidth < allowedWidth) {
      return;
    }

    const allComponents = document.querySelectorAll('a');

    for (const component of allComponents) {
      component.classList.remove('opacity-20');
    }
  }

  return (
    <>
      <a target="_blank" rel="noopener noreferrer" {...props} href={ComponentDatabase[componentId].url} data-component={componentId} onMouseOver={onHover} onMouseOut={onHoverEnd} >
        {children ? children : ComponentDatabase[componentId].title}
      </a>
    </>
  )
}

export default ComponentLink