module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://39f96579da474707b4c0948c835e526a@o4504189977952256.ingest.sentry.io/4504189979066368","sampleRate":1,"enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":630,"showCaptions":false,"markdownCaptions":false,"linkImagesToOriginal":false,"backgroundColor":"#fff","disableBgImageOnAlpha":true,"disableBgImage":true,"quality":85}},"gatsby-remark-copy-linked-files","gatsby-remark-smartypants","gatsby-remark-slug"],"extensions":[".md",".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":[],"excludePaths":["/"],"height":3,"prependToBody":false,"color":"#A58F8F","footerHeight":200,"headerHeight":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-5B4WH6R","cookieName":"google-tagmanager","dataLayerName":"gatsby"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ivan Lesar - Blog","short_name":"IvanLesar","start_url":"/","background_color":"#EEEEF8","theme_color":"#B18C75","display":"minimal-ui","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"34fe3e403f640400f95ff9e747ff0a10"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
