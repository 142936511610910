const ComponentDatabase = {
  esp8266: {
    title: "ESP8266 Microcontroller",
    description: "AliExpress",
    image: "/components/nodemcu.png",
    url: "https://s.click.aliexpress.com/e/_DBySjiX",
  },
  esp32: {
    title: "ESP32 Microcontroller",
    description: "AliExpress",
    image: "/components/esp32.png",
    url: "https://s.click.aliexpress.com/e/_DmH0AvD",
  },
  esp32cam: {
    title: "ESP32 Cam",
    description: "AliExpress",
    image: "/components/esp32-cam.png",
    url: "https://s.click.aliexpress.com/e/_De5hCJV",
  },
  capacitiveMoistureSensor: {
    title: "Capacitive Moisture Sensor",
    description: "AliExpress",
    image: "/components/moisture-sensor.png",
    url: "https://s.click.aliexpress.com/e/_Deq48bH",
  },
  microUsbCable: {
    title: "Micro USB cable",
    description: "AliExpress",
    image: "/components/usb-cable.png",
    url: "https://s.click.aliexpress.com/e/_De5hCJV",
  },
  ikeaCoaster: {
    title: "Coasters",
    description: "IKEA",
    image: "/components/coasters.png",
    url: "https://www.ikea.com/gb/en/p/ikea-365-coaster-cork-30282946/",
  },
  jumperWires: {
    title: "Jumper wires",
    description: "AliExpress",
    image: "/components/jumper-wires.png",
    url: "https://s.click.aliexpress.com/e/_DBDZ0or",
  },
  potentiometer: {
    title: "Analog Potentiometer",
    description: "AliExpress",
    image: "/components/potentiometer.png",
    url: "https://s.click.aliexpress.com/e/_DmRx5qR",
  },
  lcdScreen16x2: {
    title: "16x2 LCD Screen with I2C",
    description: "AliExpress",
    image: "/components/lcd-16x2.png",
    url: "https://s.click.aliexpress.com/e/_DFwDAO7",
  },
  lcdScreen20x4: {
    title: "20x4 LCD Screen with I2C",
    description: "AliExpress",
    image: "/components/lcd-20x4.png",
    url: "https://s.click.aliexpress.com/e/_A2NKJt",
  },
  ledStrip: {
    title: "Addressable LED Strip",
    description: "AliExpress",
    image: "/components/addressable-led-strip.png",
    url: "https://s.click.aliexpress.com/e/_DdgZNpH",
  },
  magnets: {
    title: "Neodymium magnets",
    description: "AliExpress",
    image: "/components/neo-magnets.png",
    url: "https://s.click.aliexpress.com/e/_DCPnIkB",
  },
  lcdController: {
    title: "LCD Controller",
    description: "AliExpress",
    image: "/components/lcd-controller.png",
    url: "https://s.click.aliexpress.com/e/_DBD98tt",
  },
  breadboard: {
    title: "Breadboard",
    description: "AliExpress",
    image: "/components/breadboard.png",
    url: "https://s.click.aliexpress.com/e/_DBUNRF1",
  },
  drill: {
    title: "Drill",
    description: "AliExpress",
    image: "/components/drill.png",
    url: "https://s.click.aliexpress.com/e/_DERoNpH",
  },
  enclosure: {
    title: "Project enclosure",
    description: "AliExpress",
    image: "/components/enclosure.png",
    url: "https://s.click.aliexpress.com/e/_DnlII3d",
  },
  powerSupplyModule: {
    title: "Power supply module",
    description: "AliExpress",
    image: "/components/powerSupplyModule.png",
    url: "https://s.click.aliexpress.com/e/_Dcu325x",
  },
  waterPump: {
    title: "Water pump",
    description: "AliExpress",
    image: "/components/water_pump.png",
    url: "https://s.click.aliexpress.com/e/_DC4JOTr",
  },
  relay: {
    title: "Relay switch",
    description: "AliExpress",
    image: "/components/relay.png",
    url: "https://s.click.aliexpress.com/e/_DC4JOTr",
  },
  power12v: {
    title: "12V power supply",
    description: "AliExpress",
    image: "/components/power12v.png",
    url: "https://s.click.aliexpress.com/e/_DdGZaSH",
  },
}

export default ComponentDatabase
