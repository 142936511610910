import { getDatabase, increment, ref, set } from "firebase/database"
import app from "gatsby-plugin-firebase-v9.0"

function incrementCounterByQuery(query) {
  set(ref(getDatabase(app), query), increment(1))
}

function getCookie(name) {
  var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"))
  if (match) return match[2]
}

export function incrementCounter(location, query) {
  const ownerCookie = getCookie("owner")
  console.log("trying to increment", query)
  // document.cookie = "owner=Ivan; expires=Thu, 17 Jan 2030 12:00:00 UTC";
  if (!["localhost", "127.0.0.1"].includes(location.hostname) && !ownerCookie) {
    console.log("EXECUTING")
    incrementCounterByQuery(query)
  }
}
